.RatingSystemCon1 {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 12px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25) inset,
    0px 4px 12px 2px rgba(0, 0, 0, 0.5);
}
.RatingSystemCon2 {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.RatingSystemText1 {
  flex: 1 0 0;
  color: var(--app-green, #3a7d44);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.RatingSystemCon3 {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.RatingSystemText2 {
  align-self: stretch;
  color: var(--Success-color-green, #33b546);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.RatingSystemCon4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  align-self: stretch;
}
.RatingSystemCon5 {
  display: flex;
  height: 48px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  gap: 1px;
  align-self: stretch;
  border-radius: 6px;
  background: linear-gradient(
    270deg,
    #03c11f 0%,
    #fffb00 59.5%,
    #f8a501 80%,
    #f00 100%
  );
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset;
}
.RatingSystemCon6 {
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  /* border-radius: 5px 0px 0px 5px; */
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
}
.RatingSystemText3 {
  flex: 1 0 0;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.RatingSystemCon7 {
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}
.RatingSystemCon8 {
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  /* border-radius: 0px 5px 5px 0px; */
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);
}
.RatingSystemConSelectRatingActive {
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid #33b546;
  background: none;
  border-radius: 5px;
}
.RatingSystemCon9 {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.RatingSystemText4 {
  flex: 1 0 0;
  color: var(--app-orange, #ff7043);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.RatingSystemCon10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.RatingSystemText5 {
  align-self: stretch;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.RatingSystemCon11 {
  display: flex;
  min-height: 141px;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
}
.RatingSystemText6 {
  flex: 1 0 0;
  align-self: stretch;
  color: var(--app-charcoal-dark, #2b2b2b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.RatingSystemCon12 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  background: var(
    --Mercado-MAI-pro,
    linear-gradient(0deg, #f80 0%, #e2ff04 100%)
  );
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.25);
  opacity: 0.3;
}
.RatingSystemCon12Active {
  opacity: 1;
}
.RatingSystemText7 {
  flex: 1 0 0;
  color: var(--app-white, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.RatingSystemCon13 {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.RatingSystemText8 {
  flex: 1 0 0;
  color: var(--app-charcoal-dark, #2b2b2b);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
